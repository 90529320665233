import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo-home"
import Layout from "../components/layout"
import "../styles/usage.scss"
const SecondPage = () => (
  <Layout>
    <SEO
      title="Trekathlon"
      description="Usage Terms"
      keywords={[
        `Trekking`,
        `Travel`,
        `Tips`,
        `Budget`,
        `Photography`,
        `Travel`,
      ]}
      meta={[{ name: "robots", content: "noindex" }]}
    />
    <section className="usage">
      <h1>Conditions d'utilisation</h1>
      <h2>Conditions d'utilisation et politique de confidentialité</h2>
      Les conditions d'utilisation et clauses de politique de confidentialité
      définissent les conditions qui doivent être acceptées afin d'utiliser ce
      site web.
      <h2>Propriété et reproduction</h2>
      La structure générale, textes, images animées ou fixes, sons,
      savoir-faire, dessins, graphismes et tous autres éléments composant ce
      site web sont de l’utilisation exclusive de monsieur Frédéric Hautecoeur.
      Toute reproduction totale ou partielle de ce site, par quelques procédés
      que ce soient, sans autorisation expresse de monsieur Frédéric Hautecoeur,
      est interdite et constituerait une contrefaçon sanctionnée par les
      articles{" "}
      <Link to="http://codes.droit.org/CodV3/propriete_intellectuelle.pdf">
        L335-2
      </Link>{" "}
      et suivants du Code de la propriété intellectuelle.
      <h2>Limitation de responsabilité</h2>
      Ce site comporte des informations mises à disposition par des liens
      hypertextes vers d'autres sites qui n'ont pas été développés par monsieur
      Frédéric Hautecoeur. Le contenu mis à disposition sur le site est fourni à
      titre informatif. L'existence d'un lien de ce site vers un autre site ne
      constitue pas une validation de ce site ou de son contenu. Il appartient à
      l'internaute d'utiliser ces informations avec discernement et esprit
      critique. La responsabilité de monsieur Frédéric Hautecoeur ne saurait
      être engagée du fait aux informations, opinions et recommandations
      formulées par des tiers.
      <h2>Propriété Intellectuelle</h2>
      Tous les éléments du site (textes, photos, logos,…) sont et restent la
      propriété de monsieur Frédéric Hautecoeur. Aucune partie de ce site ou de
      son contenu ne peut être copiée, reproduite, rediffusée ou reproduite de
      quelque manière que ce soit sur n'importe quel ordinateur, serveur, site
      internet ou autre moyen de publication ou de distribution ou pour toute
      entreprise commerciale sans l'accord préalable de l'auteur et photographe.
    </section>
  </Layout>
)

export default SecondPage
